* {
  font-family: "Shabnam";
  scrollbar-width: thin;
  scrollbar-color: #9ca3af #e5e7eb;
  /* user-select: none !important; */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes spin2 {
  to {
    transform: rotate(-360deg);
  }
}
.animate-spin2 {
  animation: spin2 1s linear infinite;
}
@font-face {
  font-family: "Shabnam";
  src: url("../fonts/Shabnam-FD.eot");
  src: url("../fonts/Shabnam-FD.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Shabnam-FD.woff2") format("woff2"),
    url("../fonts/Shabnam-FD.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ShabnamLight";
  src: url("../fonts/Shabnam-Light-FD.eot");
  src: url("../fonts/Shabnam-Light-FD.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Shabnam-Light-FD.woff2") format("woff2"),
    url("../fonts/Shabnam-Light-FD.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ShabnamBold";
  src: url("../fonts/Shabnam-Bold-FD.eot");
  src: url("../fonts/Shabnam-Bold-FD.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Shabnam-Bold-FD.woff2") format("woff2"),
    url("../fonts/Shabnam-Bold-FD.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
